@tailwind base;
@tailwind components;
@tailwind utilities;

.teko-unique {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400; /* Change this value as needed */
}

body {
  background: url("../public/wallpaper.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 6px;
}

::-webkit-scrollbar-track {
  /* Customize the scrollbar track */
  background-color: aliceblue;
}

::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  background: #201f31;
  border-radius: 10px;
}

/* Add this CSS to your main CSS file */
.glow-effect {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.glow-effect:hover {
  transform: scale(1.1); /* Zoom in effect */
  box-shadow: 0 0 20px rgba(0, 102, 255, 0.7), 0 0 40px rgba(0, 102, 255, 0.5); /* Blue glow effect */
}
